import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author } = data.site.siteMetadata;
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: "3.5em",
              alignItems: `center`,
              flexDirection: `column`,
              textAlign: `center`,
              fontSize: ".9rem"
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: 0,
                marginBottom: `1em`,
                minWidth: 75,
                borderRadius: `100%`,
                boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.9)"
              }}
              imgStyle={{
                borderRadius: `50%`,
                zIndex: "-2"
              }}
            />
            <p style={{ marginBottom: 0 }}>
              Official home of Dan on the web!
              <br />
              Writing about programming and maybe other stuff.
            </p>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/danface2.jpg/" }) {
      childImageSharp {
        fixed(width: 75, height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;
